import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Video Player
import ReactPlayer from 'react-player'

// Import Images
import AppStore from '../images/badges/app-store.svg'
import GooglePlay from '../images/badges/google-play.svg'
import RooamTouchIcon from '../images/rooam-touch-icon.svg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

const Download = () => {
  const data = useStaticQuery(graphql`
    query {
      iPhoneHardware: file(relativePath: { eq: "phones/iPhone-hardware.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      rooamAppLocation: file(relativePath: { eq: "phones/Rooam-App-Android-Location.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      rooamAppPay: file(relativePath: { eq: "phones/Rooam-App-Livefeed-Apple-Pay-Face-ID.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `) 
  return (
    <Layout>
      <SEO title="Rooam - Pay your tab or check from your phone." description="A contactless payment solution allowing you pay your tab or check directly from your phone." />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">   
          <div className="peak-wrap no-top">
            <div className="icon touch-icon">
              <img src={RooamTouchIcon} alt="Rooam Touch Icon" />
            </div>
          </div>
          <h1 className="no-top text-white">Pay from your phone</h1>
          <p className="lead no-top-bottom text-light-grey">Rooam is a contactless payment solution allowing you to open, view and pay your tab or check directly from your phone.</p>
          <span className="badge no-bottom-margin">
            <a href="https://apps.apple.com/us/app/rooam/id1141914731" target="_blank" rel="noopener noreferrer">
              <img src={AppStore} alt="App Store badge" />
            </a>
          </span>
          <span className="badge no-bottom-margin">
            <a href="https://play.google.com/store/apps/details?id=co.rooam.rooamapp" target="_blank" rel="noopener noreferrer">
              <img src={GooglePlay} alt="Google Play badge" />
            </a>
          </span>
        </div>
      </div>

      <div className="section">
        <div className="container small">
          <div className="phone-wrap">
            <Fade bottom distance={'1em'} duration={800}>
              <div className="phone-hardware one-three">
                <div className="hardware-screen background-dark">
                  <div className="player-wrapper tall">
                    <ReactPlayer
                      url={[
                        {src: '../videos/Rooam-App-Flow.mp4', type: 'video/mp4'},
                        {src: '../videos/Rooam-App-Flow.webm', type: 'video/webm'}
                      ]}
                      className="react-player"
                      playing
                      width="100%"
                      height="100%"
                      loop={true}
                      playsinline={true}
                      muted={true}
                    />
                  </div>
                </div>
                <Img
                  fluid={data.iPhoneHardware.childImageSharp.fluid}
                  alt="iPhone hardware shell to host the animating video"
                  loading="eager"
                />
              </div>
            </Fade>
            <Fade bottom distance={'1em'} duration={800} delay={600}>
              <div className="phone-hardware two-three">
                <Img
                  fluid={data.rooamAppLocation.childImageSharp.fluid}
                  alt="Rooam app viewing a location"
                  loading="eager"
                />
              </div>
            </Fade>
            <Fade bottom distance={'1em'} duration={800} delay={800}>
              <div className="phone-hardware three-three">
                <Img
                  fluid={data.rooamAppPay.childImageSharp.fluid}
                  alt="Rooam app showing Apple Pay sheet"
                  loading="eager"
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Download
